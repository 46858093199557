do ->

    # Form responsible for displaying and editing fund screenshots.
    fcCompanyNetworkSearch = ($window, FCCompanyNetworkSearchData, NetworkSearch, NetworkSearchResult, CompanySavedContact) ->

        thisController = ->
            vm = @

            locationQueryParams = $window.location.search.substring(1).split('&')
            vm.locationSearch = {}
            for queryParam in locationQueryParams
                if queryParam
                    parts = queryParam.split('=')
                    vm.locationSearch[parts[0]] = parts[1]

            setLocationSearch = (name, value) ->
                if value != null
                    vm.locationSearch[name] = value
                else
                    delete vm.locationSearch[name]

                queryParams = []
                for name, value of vm.locationSearch
                    queryParams.push("#{name}=#{$window.encodeURI(value)}")
                $window.history.replaceState({}, "", "?#{queryParams.join('&')}")

            vm.popularSearchesPopover = 'companies/popular-searches.popover.html'
            vm.popularSearchesPopoverIsOpen = false
            vm.searching = false
            vm.hasMoreResults = false
            vm.numResults = 0
            vm.searchResults = []
            vm.senderInfo = FCCompanyNetworkSearchData.senderInfo
            vm.showSavedContacts = false
            vm.savedContacts = FCCompanyNetworkSearchData.savedContacts
            vm.connectionsStats = FCCompanyNetworkSearchData.connectionsStats
            vm.networkSearch = new NetworkSearch()
            vm.networkSearch.funds = FCCompanyNetworkSearchData.funds
            vm.networkSearch.q = $window.decodeURI(vm.locationSearch['q'] or '')
            vm.networkSearch.page = Number(vm.locationSearch['page']) or 1

            vm.networkSearch.scopes = {
                'investors': false
                'founders': false
                'staff': false
                'members': false
                'network': false
            }
            scopes = vm.locationSearch['scopes']
            if scopes
                scopes = scopes.split(',')
            else
                scopes = ['investors']

            for scope in scopes
                vm.networkSearch.scopes[scope] = true

            vm.setQuery = (query) ->
                vm.networkSearch.q = query
                vm.popularSearchesPopoverIsOpen = false
                vm.search(true, true)

            vm.search = (resetSearchResults, enableAllScopes) ->
                vm.showSavedContacts = false
                setLocationSearch('show_contacts', null)

                vm.searching = true

                if resetSearchResults
                    vm.networkSearch.page = 1
                    vm.searchResults = []

                if enableAllScopes
                    for scope, enabled of vm.networkSearch.scopes
                        vm.networkSearch.scopes[scope] = true

                setLocationSearch('q', vm.networkSearch.q)
                setLocationSearch('scopes', vm.networkSearch.getScopes())
                setLocationSearch('page', vm.networkSearch.page)
                vm.networkSearch
                    .$search()
                    .$promise
                    .then (response) ->
                        newSearchResults = response.results.search_results.map (result) ->
                            new NetworkSearchResult(result)
                        vm.searchResults = vm.searchResults.concat(newSearchResults)
                        vm.hasMoreResults = response.results.has_more_results
                        vm.numResults = response.results.num_results
                        vm.connectionsStats = response.results.result_counts
                        vm.networkSearch.page += 1
                        vm.searching = false
                    .finally ->
                        vm.searching = false

            vm.displaySavedContacts = ->
                vm.showSavedContacts = true

                setLocationSearch('show_contacts', '1')
                setLocationSearch('q', null)
                setLocationSearch('scopes', null)
                setLocationSearch('page', null)
                vm.networkSearch.q = ''

                _.each vm.networkSearch.scopes, (val, key) ->
                    vm.networkSearch.scopes[key] = false

                vm.searching = true
                vm.searchResults = []

                CompanySavedContact.listContacts(vm.savedContacts.company_id)
                    .then (response) ->
                        vm.searchResults = (new NetworkSearchResult(c) for c in response.data)
                        vm.hasMoreResults = false
                    .finally ->
                        vm.numResults = vm.searchResults.length
                        vm.searching = false

            if vm.locationSearch['show_contacts']
                vm.displaySavedContacts()
            else
                vm.search()

            # End fcCompanyNetworkSearch
            return

        thisDirective =
            templateUrl: 'companies/network-search.directive.html'
            restrict: 'A'
            bindToController: true
            controllerAs: 'cns'
            controller: thisController
            replace: true
        return thisDirective


    angular
        .module 'fundersclub.companies'
        .directive 'fcCompanyNetworkSearch', fcCompanyNetworkSearch
